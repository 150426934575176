import React, { Component } from 'react'
import classes from './About.module.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css"; 

class About extends Component {
  render() {
    return (
      <div className={classes.box} id="about">
        <ScrollAnimation offset="0" animateIn="fadeInLeft" duration='2.4' animateOnce="true" initiallyVisible ={true}>
            <span  className={classes.head}>ABOUT ME</span>
            <h2 className={classes.heading}>Who Am I?</h2>
            <div className={classes.About}>
              <p>
                My name is Luke Edmondson and I recently earned my Bachelor of Science in Computer Science from Valdosta State University.
                I would like to become a software engineer. I love working with software be it on an existing code base or a brand new project.
              </p>
              <p className={classes.br}>
                I love exploring new technologies and learning how to integrate them with my existing knowledge.
                In my free time, I like to tinker with hardware in addition to working on some personal projects.
                In particular I enjoy using and learning more about various operating systems and hypervisors.
                I'm attempting to teach myself more about containers and integrating them into my personal environment.
              </p>
            </div>
        </ScrollAnimation>
      </div>
    )
  }
}

export default About;