import React, { Component } from 'react';
import './Sidebar.css';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../images/LE-Logo.png'

class Sidebar extends Component {
    render() {
       
        return (
            <div className="sidebar">
                <img src={logo} alt=''/>
                <h1><Link smooth to="/#start" className="h1_links">Luke Edmondson</Link></h1>
                <p style={{color:'black',fontWeight:'bold'}} className="email"> luke@luke-ed.dev </p>
                <ul className="sidebar-nav">
                    <li className="sidebar-nav-items"><Link smooth to="/#about" className="links">About Me</Link></li>
                    <li className="sidebar-nav-items"><Link smooth to="/#education" className="links" >Education</Link></li>
                    <li className="sidebar-nav-items"><Link smooth to="/#interest" className="links">Interest</Link></li>
                </ul>
    
                <div className="flip-card-back">
                    <ul className="sidebar-nav">
                        <li className="sidebar-nav-icons"><a href="https://www.linkedin.com/in/luke-edmondson-297b83214/" rel="noopener noreferrer" target="_blank" className="fa fas fa-linkedin fa-lg"> <span className='links-small'>LinkedIn</span></a></li>
                        <li className="sidebar-nav-icons"> <a href="https://github.com/Luke-Ed" rel="noopener noreferrer" target="_blank" className="fa fas fa-github fa-lg"> <span className='links-small'>Github</span></a></li>
                        <li className="sidebar-nav-icons"> <a href="https://www.instagram.com/luke_edm/" rel="noopener noreferrer" target="_blank" className="fa fas fa-instagram fa-lg"> <span className='links-small'>Instagram</span></a></li>
                        <li className="sidebar-nav-icons"> <a href="mailto:luke@luke-ed.dev" rel="noopener noreferrer" target="_blank" className="fa fas fa-envelope fa-lg"> <span className='links-small'>Email Me </span></a></li>
                    </ul>
                </div>
              
            </div>
        )
    }
}

export default Sidebar