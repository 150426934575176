import React, {Component} from 'react'
import { Carousel } from 'react-responsive-carousel';
import classes from './CarouselImages.module.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "animate.css/animate.min.css"; 
import {GoProject} from 'react-icons/go'
//import {FaArrowCircleDown} from 'react-icons/fa'
import Hello from '../images/Hello.jpg'
import Pencil from '../images/Pencil.jpg'

 class CarouselImages extends Component {
  render(){
    return (
      <div id="start">
        <Carousel dynamicHeight infiniteLoop={true} interval={6000} useKeyboardArrows={true} transitionTime={1700} emulateTouch showArrows={false} autoPlay  showStatus={false} showThumbs={false} >
          <div >
            <img className={classes.image} src={Hello}  alt="stating Hello"/>
            <div className={classes.h1}>
             <h1 >I'm Luke</h1>
              {/*<a href="https://drive.google.com/open?id=1UEYXr47N9yD_9JxY457Vul4BwRizNVfv" rel="noopener noreferrer" target="_blank">VIEW CV   <FaArrowCircleDown/></a> */}
            </div>
          </div>
          <div >
            <img className={classes.image} src={Pencil} alt="Pencils"/>
            <div className={classes.h2}>
            <h1> I love building</h1>
            <h1>THINGS!!</h1>
            <a href="https://github.com/Luke-Ed" rel="noopener noreferrer" target="_blank">VIEW PROJECTS   <GoProject/></a>
            </div>
          </div>
        </Carousel>
      </div>
     )
  }
}
export default CarouselImages