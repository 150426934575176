import React, { Component } from 'react'
import classes from './Interest.module.css';
import "animate.css/animate.min.css";

class Interest extends Component {
  render() {
    return (
      <div className={classes.box} id="interest">
          <span className={classes.head}>WHAT I DO:</span>
          <h2 className={classes.heading}>HERE ARE SOME OF MY AREAS OF EXPERTISE</h2>
          <div className={classes.Interest}>
              <div className={classes.app}>
                <h3>Application Development</h3>
                <p>
                  I have worked with Java and C# to build desktop applications in my studies at university.
                  I have also started working with Microsoft's Xamarin in the hopes of developing a cross platform application.
                </p>
              </div>
              <div className={classes.web}>
                <h3>Web Development</h3>
                <p>
                  I have experience using both ASP.NET and the MERN Stack for web development.
                  In addition ot this, I have used MS-SQL and MySQL to provide additional functionality for projects I have worked on.
                </p>
              </div>
              <div className={classes.other}>
                <h3>Other Interests</h3>
                <p>
                  In addition to the other interests and skills mentioned above, I enjoy playing PC Games, dabbling with vector design, and reading.
                  I tend to read fantasy style novels most times, with the occasional comic or graphic novel.
                  I love Star Wars, Marvel, and DC in addition to quiet a few other fandoms.
                </p>
              </div>
          </div>
      </div>
    )
  }
}

export default Interest;