import React, { Component } from 'react'
import classes from './Project.module.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

class Projects extends Component {
  render() {
    return (
      <div className={classes.box} id="interest">
        <ScrollAnimation offset="0" animateIn="fadeInLeft" duration='2.4' animateOnce="true" initiallyVisible={true}>
          <span className={classes.head}>WHAT HAVE I WORKED ON?</span>
          <h2 className={classes.heading}>HERE ARE SOME OF MY PAST PROJECTS</h2>
          <div className={classes.Interest}>
            <ScrollAnimation offset="0" animateIn="fadeInLeft" duration='2.4' animateOnce="true" initiallyVisible={true}>
              <div className={classes.softwareEngineering}>
                <h3>Mock Electronic Medical Records System</h3>
                <p>
                  In my Software Engineering class, I worked in a group of four to design and build a project of our choice.
                  Our group decided to create an example of an electronic medical records system using Windows Forms, with C# and XAML as a backend.
                  Our system stored records using a MS-SQL database and provides the user with four different interfaces, depending on the access level of the user.
                  
                </p>
                <p>
                  <a href='https://github.com/Luke-Ed/VSU-Software-Engineering-Group-Project' style={{textDecoration: "underline"}}>Github Repository</a>
                </p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation offset="0" animateIn="fadeInLeft" duration='2.4' animateOnce="true" initiallyVisible={true}>
              <div className={classes.fullStack}>
                <h3>Mock Social Media Site</h3>
                <p>
                    At the end of my FullStack development course, I worked towards developing a mock social media site,using a combination of skills learned throughout the course.
                    This site uses a MongoDB to store users, profiles, and posts.
                    A REST API is used to perform all supported actions creating, editing, and deleting all of the aforementioned stored data.
                    This application uses Redux and React for the GUI, which calls upon the REST API to perform the supported actions.
                    Unfortunately we ran out of time during the semester to finish this project, so it is not completely functional.
                </p>
                <p>
                  <a href='https://connecty-luke-edmondson.herokuapp.com' style={{textDecoration: 'underline'}}> Live Demo </a>
                </p>
              </div>
            </ScrollAnimation>
            <ScrollAnimation offset="0" animateIn="fadeInLeft" duration='2.4' animateOnce="true" initiallyVisible={true}>
              <div className={classes.seniorSeminar}>
                <h3>Senior Seminar Game</h3>
                <p>
                  In my senior seminar class, I worked in a group of five to design and build a project of our choice.
                  My group choose to make a game using the Godot engine, with Mono support.
                  We chose to write the functional code for our project using C#.
                  Different members of our team performed different roles, I primarily focused on code cleanliness and optimization since I had the most C# experience.
                </p>
                <p>
                  <a href='https://github.com/Luke-Ed/VSU-Senior-Seminar' style={{textDecoration: 'underline'}}>Github Repository</a>
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    )
  }
}

export default Projects;