import React, { Component } from 'react';
import classes from './Education.module.css';
import "animate.css/animate.min.css";
import { MdSchool } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { FaSchool } from 'react-icons/fa';

class Education extends Component {
  render() {
    return (
      <div className={classes.box} id="education">
        <span  className={classes.head}>MY JOURNEY</span>
        <section className={classes.container}>
          <div className={classes.container_content}>
            <div className={classes.row}>
              <div className={classes.row_md_12}>
                <div className={classes.timeline_centered}>
                  <article className={classes.timeline_entry}>
                    <div className={`${classes.timeline_icon} ${classes.timeline_icon_2}`} >
                        <FaSchool />
                    </div>
                    <div className={classes.label}>
                    <h2>IT Student Intern <span>2016-2018</span></h2>
                    <p>I was an IT Student intern for Brooks County School System. I performed a variety of tasks, including troubleshooting computers and servers, imaging computers, and managing users and computers using Active Directory.</p>
                    </div>
                  </article>
                  <article className={classes.timeline_entry}>
                    <div className={classes.timeline_icon} >
                    <MdSchool />
                    </div>
                    <div className={classes.label}>
                    <h2 >Undergraduate at VSU <span>2018-2021</span></h2>
                    <p>I recently completed my undergraduate degree in Computer Science from <a href='https://valdosta.edu/'>VSU</a>. </p>
                    </div>
                  </article>
                  <article>
                    <div className={`${classes.timeline_icon} ${classes.timeline_icon_4}`} >
                        <MdWork />
                    </div>
                    <div className={classes.label}>
                    <h2 >Information Security Internship <span>2019-Present</span></h2>
                    <p>I'm a student intern at Valdosta State University's Information Security Department. This has allowed me to perform a multitude of different tasks, including code and application analysis.</p>
                    </div>
                    <div className={classes.timeline_entry_inner}><div  className={classes.timeline_icon_3}></div></div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Education;
